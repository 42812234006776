* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Noto Sans TC", sans-serif;
}

div.Header {
  .custom-nav {
    color: white;
    font-size: 26px;
  }
  #basic-nav-dropdown {
    color: white;
    font-size: 26px;
  }
  .custom-nav-dropdown {
    font-size: 26px;
    color: rgb(159, 45, 32);
  }
  i {
    padding-right: 5px;
  }
  div.Background-fixed {
    div.wrap {
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100vh;
      position: fixed;
      z-index: -98;
    }
    img.fixed-background {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      background-attachment: fixed;
      position: fixed;
      z-index: -99;
      top: 0;
      left: 0;
    }
  }
}

div.Homepage {
  div.banner {
    min-height: 70vh;
    animation-name: backgroundImageHandler;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    background-size: cover;
    background-position: center;
    position: relative;
    // background-attachment: fixed;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    div.banner-overlay {
      background-color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 70vh;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: -1;
    }
    h3 {
      color: white;
      font-size: 3rem;
      text-align: center;
      background-image: url("../");
    }
    @keyframes backgroundImageHandler {
      0% {
        background-image: url("/public/images/Sakura.jpg");
      }
      25% {
        background-image: url("/public/images/Sakura.jpg");
      }
      50% {
        background-image: url("/public/images/Itsukushima Shrine.jpg");
      }
      100% {
        background-image: url("/public/images/Dotonbori.jpg");
      }
    }
  }

  div.information {
    background-color: rgb(159, 45, 32);
    padding: 2rem;
    h3 {
      text-align: center;
      color: white;
    }
    div.circles {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      div.circle {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        flex: 0 1 400px;
        align-items: center;
        img {
          width: 80%;
          border-radius: 50%;
          transition: all 0.1s ease-in-out;
          padding: 1rem;
          &:hover {
            border: 3px solid white;
          }
        }
        p {
          margin-top: 10px;
          color: white;
          font-size: 1.5rem;
        }
      }
    }
  }

  div.banner-2 {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 5rem;
      font-weight: bolder;
      color: white;
    }
  }

  div.google-map {
    background-color: rgb(159, 45, 32);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 2rem;
      color: white;
    }
    p {
      font-size: 1.5rem;
      color: white;
      padding: 1rem;
    }
    iframe {
      width: 100%;
      height: 40vh;
    }
  }
}

div.register {
  .form-custom {
    background-color: rgb(159, 45, 32);
    width: 100%;
    height: 100%;
    padding: 2rem;
    margin-top: 200px;
    color: white;
    font-size: 2rem;
  }
}

div.login {
  .form-custom {
    background-color: rgb(159, 45, 32);
    width: 100%;
    height: 100%;
    padding: 2rem;
    margin-top: 200px;
    color: white;
    font-size: 2rem;
  }
}

div.profile {
  background-color: rgb(159, 45, 32);
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-top: 200px;
  color: white;
  font-size: 1.5rem;
}

div.posted-tour {
  background-color: rgb(159, 45, 32);
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-top: 200px;
  font-size: 1.5rem;
  .tourData {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    i {
      margin-right: 5px;
    }
    .description {
      white-space: pre-line;
    }
    .comment-custom {
      border-bottom: 3px solid rgb(159, 45, 32);
    }
  }
}

div.post-tour {
  background-color: rgb(159, 45, 32);
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-top: 200px;
  font-size: 1.5rem;
  color: white;
  .form-custom {
    font-size: 1.5rem;
  }
}

div.search-tour {
  background-color: rgb(159, 45, 32);
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-top: 200px;
  font-size: 1.5rem;
  color: white;
  .searchbar-custom {
    flex-wrap: nowrap;
    width: 70vw;
    font-size: 2rem;
  }
  .searchbar-custom-button {
    width: 30vw;
    font-size: 2rem;
  }
  div.search-result {
    .card {
      padding: 1rem;
    }
    .description {
      white-space: pre-line;
    }
    .comment-custom {
      border-bottom: 3px solid rgb(159, 45, 32);
    }
  }
  .commentbar-custom {
    flex-wrap: nowrap;
    width: 70vw;
    font-size: 2rem;
  }
}

div.liked-tour {
  background-color: rgb(159, 45, 32);
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-top: 200px;
  font-size: 1.5rem;
  color: white;
  .tour-data {
    .card {
      padding: 1rem;
    }
    .description {
      white-space: pre-line;
    }
  }
}

div.patch-tour {
  background-color: rgb(159, 45, 32);
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-top: 200px;
  font-size: 1.5rem;
  color: white;
  align-items: center;
  justify-content: center;
  .description {
    white-space: pre-line;
  }
  .patch-form {
    .form-custom {
      font-size: 1.5rem;
    }
  }
}

div.contact {
  background-color: rgb(159, 45, 32);
  width: 100%;
  height: 100%;
  padding: 3rem;
  margin-top: 200px;
  font-size: 1.5rem;
  color: white;
}

div.Footer {
  h2 {
    text-align: center;
    color: white;
    margin-top: 2rem;
  }
}
